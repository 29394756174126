<template>
  <div class="manager-integral-page">
    <!-- 加分管理 -->
    <el-card class="card-item add-card" shadow="never">
      <div slot="header" class="card-title">
        <span class="title">
          <!-- <i class="iconfont icon-houtaishangpinguanli"></i>-->

          <img
            class="icon-houtaishangpinguanli"
            src="../../assets/images/icon/icon_addscore.png"
            alt=""
          />
          加分管理</span
        >
        <!-- <el-button type="primary" size="small" @click="handleAdd(1)">新增</el-button> -->
      </div>
      <div class="table-container">
        <el-table :data="addTableData" style="width: 100%" height="100%">
          <el-table-column prop="name" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="score" label="分值" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template slot-scope="scope">
              <div class="opt-container">
                <!-- <i
                  class="iconfont icon-bianji1"
                  title="编辑"
                  @click="goto(scope.row, 'add')"
                ></i> -->
                <img
                  class="icon-bianji"
                  src="../../assets/images/icon/icon_bianji.png"
                  alt=""
                  @click="goto(scope.row, 'add')"
                />
                <!-- <i class="iconfont icon-shanchu3" title="删除"></i> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <!-- 加分管理 -->
    <el-card class="card-item reduce-card" shadow="never">
      <div slot="header" class="card-title">
        <span class="title">
          <img
            class="icon-bianji2"
            src="../../assets/images/icon/icon_minusscore.png"
            alt=""
          />
          扣分管理</span
        >
        <!-- <el-button type="primary" size="small" @click="handleAdd(-1)">新增</el-button> -->
      </div>
      <div class="table-container">
        <el-table :data="reduceTableData" style="width: 100%" height="100%">
          <el-table-column prop="name" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="score" label="分值" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template slot-scope="scope">
              <div class="opt-container">
                <!-- <i
                  class="iconfont icon-bianji1"
                  title="编辑"
                  @click="goto(scope.row, 'reduce')"
                ></i> -->
                <img
                  class="icon-bianji"
                  src="../../assets/images/icon/icon_bianji.png"
                  alt=""
                  @click="goto(scope.row, 'reduce')"
                />
                <!-- <i class="iconfont icon-shanchu3" title="删除"></i> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      custom-class="info-dialog"
      width="40%"
    >
      <div class="dialog-title" slot="title">编辑</div>
      <div class="dialog-content">
        <el-form
          :model="form"
          label-width="100px"
          :rules="rules"
          ref="scoreForm"
        >
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="分值" prop="score">
            <!-- <el-input-number
              v-model="form.score"
              controls-position="right"
              :min="1"
              style="width: 100%"
              v-if="type == 'add'"
            ></el-input-number> -->
            <el-input
              v-model="form.score"
              :min="0"
              v-if="type == 'add'"
            ></el-input>
            <el-input v-model="form.score" :max="0" v-else></el-input>
            <!-- <el-input-number
              v-model="form.score"
              controls-position="right"
              :max="-1"
              style="width: 100%"
              v-else
            ></el-input-number> -->
          </el-form-item>
          <!-- <el-form-item label="描述" :label-width="'100px'">
            <el-input v-model="form.comment" type="textarea"></el-input>
          </el-form-item> -->
        </el-form>
        <div class="footer-box">
          <el-button type="primary" @click="handleSubmit" class="confirm-btn"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var validateAddScore = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入"));
      } else {
        let reg = /^(\+?[1-9]\d*|0)$/;
        if (reg.test(this.form.score)) {
          callback();
        } else {
          callback(new Error("请输入正整数"));
        }
      }
    };
    var validateReduceScore = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入"));
      } else {
        let reg = /^(-[1-9]\d*|0)$/;
        if (reg.test(this.form.score)) {
          callback();
        } else {
          callback(new Error("请输入负整数"));
        }
      }
    };
    return {
      SUCCESS_CODE: 0,
      dialogVisible: false,
      addTableData: [],
      reduceTableData: [],
      form: {
        name: "",
        score: "",
        comment: "",
      },
      addRules: { validator: validateAddScore, trigger: "blur" },
      reduceRules: { validator: validateReduceScore, trigger: "blur" },
      rules: {
        name: [{ required: true, trigger: "blur", message: "请输入" }],
        score: [{ required: true, trigger: "blur", message: "请输入" }],
      },
      type: null,
    };
  },
  created() {
    this.getIntegralList();
  },
  methods: {
    // 获取积分列表
    getIntegralList() {
      this.addTableData.splice(0, this.addTableData.length)
      this.reduceTableData.splice(0, this.reduceTableData.length)
      this.$http.get("/score-rule/list").then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          res.data.forEach(element => {
            if(element.type==1){
              this.addTableData.push(element)
            }else{
              this.reduceTableData.push(element);
            }
          });
        }
      });
    },

    goto(data, type) {
      console.log(data);
      this.type = type;
      if (this.type == "add") {
        this.rules.score[1] = this.addRules;
      } else {
        this.rules.score[1] = this.reduceRules;
      }
      this.form.id = data.id;
      this.form.name = data.name;
      this.form.score = data.score;
      this.dialogVisible = true;
    },
    handleAdd(type) {
      // type为1是加分，-1是减分
    },
    handleSubmit() {
      this.$refs.scoreForm.validate((valid) => {
        if (valid) {
          this.$http
            .post("/score-rule/update", this.form)
            .then((res) => {
              if (res.code == this.SUCCESS_CODE) {
                this.$message.success(res.msg);
                this.getIntegralList();
              } else {
                this.$message.error(res.msg);
              }
              this.dialogVisible = false;
            })
            .catch((err) => {
              this.$message.error(err);
              this.dialogVisible = false;
            });
        } else {
          return;
        }
      });
    },
  },
};
</script>


